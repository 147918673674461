import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPromotions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/promotion', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchPromotion(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/promotion/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updatePromotion(ctx, { promotionData, id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/promotion/${id}`, promotionData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deletePromotion(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/promotion/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addPromotion(ctx, promotionData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/promotion', promotionData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchSubscriptions(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/subscription/options')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
