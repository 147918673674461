<template>
  <b-sidebar
    id="add-new-promotion-sidebar"
    :visible="isAddNewPromotionSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-promotion-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          إضافة {{ $t('promotion') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- name -->

          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group
              label="إسم العرض الترويجي"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="promotionData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- end name -->
          <!-- code -->

          <validation-provider
            #default="validationContext"
            name="code"
            rules="required"
          >
            <b-form-group
              label="كود العرض"
              label-for="code"
            >
              <b-form-input
                id="code"
                v-model="promotionData.code"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- end code -->
          <!-- start_at -->

          <validation-provider
            #default="validationContext"
            vid="start_at"
            name="start_at"
            rules="required"
          >
            <b-form-group
              label="تاريخ بداية العرض"
              label-for="start_at"
              :state="getValidationState(validationContext)"
            >
              <flat-pickr
                v-model="promotionData.start_at"
                :config="config"
                class="form-control invoice-edit-input"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- end start_at -->
          <!-- end_date -->

          <!-- duration -->

          <validation-provider
            #default="validationContext"
            name="duration"
            rules="required"
          >
            <b-form-group
              label="عدد أشهر العرض"
              label-for="duration"
            >
              <b-form-input
                id="duration"
                v-model="promotionData.duration"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- end duration -->
          <!-- discount_type -->
          <validation-provider
            #default="validationContext"
            name="discount_type"
            rules="required"
          >
            <b-form-group
              label="نوع الخصم"
              label-for="discount_type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="promotionData.discount_type"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="discount_types"
                :reduce="val => val.value"
                :clearable="false"
                input-id="discount_type"
                @input="discount_typeChanged"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- end discount_type -->
          <!-- discount_amount -->

          <validation-provider
            #default="validationContext"
            name="discount_amount"
            rules="required"
          >
            <b-form-group
              label="نسبة الخصم"
              label-for="discount_amount"
            >
              <b-form-input
                id="discount_amount"
                v-model="promotionData.discount_amount"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- end discount_amount -->
          <!-- subscription -->
          <validation-provider
            #default="validationContext"
            name="subscription"
            rules="required"
          >
            <b-form-group
              label="باقة العرض"
              label-for="subscription"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="promotionData.subscription"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="subscriptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="subscription"
                @input="subscriptionChanged"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- end subscription -->

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BButton,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref } from '@vue/composition-api';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormTextarea,
    BButton,
    vSelect,
    flatPickr,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewPromotionSidebarActive',
    event: 'update:is-add-new-promotion-sidebar-active',
  },
  props: {
    isAddNewPromotionSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      validEmail,
      config: {
        locale: Arabic,
      },
    };
  },
  methods: {
    subscriptionChanged(subscription) {
      this.promotionData.subscription_id = subscription;
    },
    discount_typeChanged(discount_type) {
      this.promotionData.discount_type_id = discount_type;
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          store.dispatch('promotion/addPromotion', this.promotionData)
            .then((response) => {
              this.$toast.success(response.data.message);
              this.$emit('refetch-data');
              this.$emit('update:is-add-new-promotion-sidebar-active', false);
            });
        }
      }).catch((err) => {
        if (err.response) {
          if (err.response.status === 422) {
            this.$toast.error(err.response.data.message);
            this.$refs.refFormObserver.setErrors(err.response.data.errors);
          } else {
            this.$toast.error(err.response.data.error);
          }
        }
        console.log(err);
      });
    },

  },
  setup(props, { emit }) {
    const blankPromotionData = {
      name: '',
      code: '',
      start_at: '',
      duration: '',
      discount_type: '',
      discount_amount: '',
      subscription: '',
    };

    const promotionData = ref(JSON.parse(JSON.stringify(blankPromotionData)));
    const resetpromotionData = () => {
      promotionData.value = JSON.parse(JSON.stringify(blankPromotionData));
    };

    const subscriptions = ref([]);
    const res = store.dispatch('promotion/fetchSubscriptions')
      .then((response) => {
        const res = response.data;
        subscriptions.value = res.map((c) => ({ label: c.name, value: c.id, ...c }));
      });

    const discount_types = [
      {
        label: 'نسبة مائوية',
        value: 'percentage',
      },
      {
        label: 'مبلغ',
        value: 'amount',
      },
    ];

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetpromotionData);

    return {
      promotionData,
      subscriptions,
      discount_types,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-promotion-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
